import { Carousel, Tooltip, Drawer, } from "flowbite"
import type { CarouselItem, IndicatorItem, DrawerOptions, TooltipOptions, TooltipInterface } from 'flowbite';

interface galleryI {
    [id: string]: {
        name: string,
        items: Array<{
            path: string,
            thumbnail: string,
            orientation: string
        }>
    }
}

const galleryRoot = "assets/images/gallery"
const gallery: galleryI = await fetch(`${galleryRoot}/index.json`).then(response => response.json());

const galleryCategoryTemplate = <HTMLTemplateElement>document.querySelector('template#galleryCategoryTemplate')
const galleryItemTemplate = <HTMLTemplateElement>document.querySelector('template#galleryItemTemplate')
const galleryItemIndicatorTemplate = <HTMLTemplateElement>document.querySelector('template#galleryItemIndicatorTemplate')
const galleryCategoryIndicator = <HTMLTemplateElement>document.querySelector('span#galleryCategoryIndicator')

const galleryCategorySelectionDrawerOptions: DrawerOptions = {
    placement: 'top',
    backdrop: true,
    backdropClasses: 'bg-neutral-950 bg-opacity-80 fixed inset-0 z-[1000]',
}
const galleryCategorySelectionDrawer = new Drawer(<HTMLDivElement>document.querySelector('div#galleryCategorySelectionDrawer'), galleryCategorySelectionDrawerOptions)

let carousel: Carousel = null

function loadGallery(id: string, position: number) {

    const carouselElement = document.getElementById('gallery');
    const carouselItems = [] as CarouselItem[]
    const carouselOptions = {
        defaultPosition: 1,
        interval: 3000,
        indicators: {
            activeClasses: 'bg-white dark:bg-gray-800',
            inactiveClasses: 'bg-white/50 dark:bg-gray-800/50 hover:bg-white dark:hover:bg-gray-800',
            items: [] as IndicatorItem[]
        }
    }
    const carouselInstanceOptions = {
        id: 'gallery',
        override: true
    };

    carousel = new Carousel(carouselElement, carouselItems, carouselOptions, carouselInstanceOptions);

    // const images: Map<number, HTMLImageElement> = new Map()

    carousel.updateOnChange(() => {
        // Set active item in url
        const url = new URL(window.location.href)

        url.searchParams.set("c", id)
        url.searchParams.set("p", carousel.getActiveItem().position.toString())

        window.history.replaceState({}, document.title, url)

        carousel.getActiveItem().el.querySelectorAll("img").forEach((element) => { element.fetchPriority = "high" })
    })

    while (galleryItemTemplate.nextElementSibling || galleryItemIndicatorTemplate.nextElementSibling) {
        galleryItemTemplate.nextElementSibling?.remove()
        galleryItemIndicatorTemplate.nextElementSibling?.remove()
    }

    gallery[id].items.forEach((item: { path: string, thumbnail: string, orientation: string }, i) => {
        const clone = <HTMLDivElement>(<HTMLTemplateElement>galleryItemTemplate.content.cloneNode(true)).firstElementChild
        const cloneIndicator = <HTMLButtonElement>(<HTMLTemplateElement>galleryItemIndicatorTemplate.content.cloneNode(true)).querySelector("button")
        const cloneIndicatorTooltip = <HTMLDivElement>(<HTMLTemplateElement>galleryItemIndicatorTemplate.content.cloneNode(true)).querySelector('div[role="tooltip"]')

        carouselItems.push({ position: i, el: clone })
        carouselOptions.indicators.items.push({ position: i, el: cloneIndicator })

        if (item.thumbnail) {
            clone.querySelector("img").src = galleryRoot + "/" + item.thumbnail
        } else {
            clone.querySelector("img").src = galleryRoot + "/" + item.path
        }


        const highResolutionImage = <HTMLImageElement>clone.querySelector("img").cloneNode(true)
        let highResolutionImageLoading = false
        clone.querySelector("img").classList.add("opacity-80")
        clone.appendChild(highResolutionImage)
        
        highResolutionImage.src = ""
        highResolutionImage.loading = "lazy"

        clone.querySelector("img").addEventListener("load", () => {
            if (!highResolutionImageLoading) {
                highResolutionImage.src = galleryRoot + "/" + item.path
                highResolutionImage.fetchPriority = "high"
                const loading = setInterval(() => {
                    if (highResolutionImage.complete) {
                        clone.querySelector("img").remove()
                        clearInterval(loading)
                    }
                }, 10)
                highResolutionImageLoading = true
            }
        })

        if (i == position 
            || i == (position - 1 < 0 ? gallery[id].items.length - 1 : position - 1) 
            || i == (position + 1 > gallery[id].items.length - 1 ? 0 : position + 1)) {
            console.log(`${i} has priority`)
            clone.querySelectorAll("img").forEach((element) => { element.fetchPriority = "high" })
        }

        if (item.orientation == "portrait") {
            clone.querySelectorAll("img").forEach((element) => { element.classList.replace("md:w-full", "md:w-auto") })
            clone.querySelectorAll("img").forEach((element) => { element.classList.replace("h-auto", "h-full") })
        }

        clone.addEventListener("touchstart", (e: TouchEvent) => {
            invalidTouch = e.touches.length > 1 || window.visualViewport.scale > 1
            touchstartX = e.touches[e.touches.length - 1].clientX
        })

        clone.addEventListener("touchmove", (e: TouchEvent) => {
            touchmoveX = e.touches[e.touches.length - 1].clientX
        })

        clone.addEventListener("mousedown", (e: MouseEvent) => {
            touchstartX = e.clientX
        })

        clone.addEventListener("mousemove", (e: MouseEvent) => {
            touchmoveX = e.clientX
        })

        clone.addEventListener("touchend", (e: TouchEvent) => {
            if (!invalidTouch) {
                if (touchmoveX - touchstartX > 10) {
                    carousel.prev()
                } else if (touchmoveX - touchstartX < -10) {
                    carousel.next()
                }
            }
            touchstartX = window.innerWidth / 2
            touchmoveX = window.innerWidth / 2

            e.preventDefault()
        })

        clone.addEventListener("mouseup", (e: MouseEvent) => {
            if (touchmoveX - touchstartX > 10) {
                carousel.prev()
            } else if (touchmoveX - touchstartX < -10) {
                carousel.next()
            } else {
                if (e.clientX < window.innerWidth / 2) {
                    carousel.prev()
                } else {
                    carousel.next()
                }
            }
            touchstartX = window.innerWidth / 2
            touchmoveX = window.innerWidth / 2
        })

        cloneIndicator.ariaLabel = "Bild " + i
        cloneIndicator.addEventListener("click", () => {
            cloneIndicator.dispatchEvent(new MouseEvent("mouseleave"))
        })

        const tooltipOptions: TooltipOptions = {
            placement: 'top',
            triggerType: 'hover'
        };

        const tooltipInstanceOptions = {
            id: "carousel-tooltip-" + i,
            override: true
        };

        const tooltip: TooltipInterface = new Tooltip(cloneIndicatorTooltip, cloneIndicator, tooltipOptions, tooltipInstanceOptions);

        cloneIndicatorTooltip.setAttribute("id", "carousel-tooltip-" + i)
        cloneIndicatorTooltip.querySelector("img").src = galleryRoot + "/" + item.thumbnail

        if (item.orientation == "portrait") {
            cloneIndicatorTooltip.querySelector("img").classList.replace("w-64", "h-64")
        }

        tooltip.init()

        galleryItemTemplate.parentElement!.appendChild(clone)
        galleryItemIndicatorTemplate.parentElement!.appendChild(cloneIndicator)
        galleryItemIndicatorTemplate.parentElement!.appendChild(cloneIndicatorTooltip)

    })

    galleryCategoryIndicator.textContent = gallery[id].name
    document.title = gallery[id].name

    // Init and Slide

    carousel.init()
    carousel.slideTo(position)
}

// Tutorial

window.addEventListener("load", async () => {
    await new Promise(resolve => setTimeout(resolve, 2000))
    document.querySelector("#tooltip-kategorieauswahl").classList.remove("invisible")
    document.querySelector("#tooltip-kategorieauswahl").classList.replace("opacity-0", "opacity-100")
    await new Promise(resolve => setTimeout(resolve, 15000))
    document.querySelector("#tooltip-kategorieauswahl").classList.replace("opacity-100", "opacity-0")
    await new Promise(resolve => setTimeout(resolve, 1000))
    document.querySelector("#tooltip-kategorieauswahl").classList.add("invisible")
})

document.querySelector("#galleryTutorialOverlayMobile").addEventListener("click", (e) => {
    document.querySelector("#galleryTutorialOverlayMobile").classList.add("hidden")
    e.preventDefault()
})

document.querySelector("#galleryTutorialOverlayMobile").addEventListener("touchstart", (e) => {
    document.querySelector("#galleryTutorialOverlayMobile").classList.add("hidden")
    e.preventDefault()
})

document.querySelector('button[data-drawer-target="galleryCategorySelectionDrawer"]').addEventListener("click", () => {
    document.querySelector("#galleryCategorySelectionDrawerTutorial")?.remove()
    document.querySelector("#tooltip-kategorieauswahl").classList.add("invisible")
})

// Carousel Buttons

document.getElementById("carouselButtonPrev").addEventListener("click", () => {
    carousel.prev()
})

document.getElementById("carouselButtonNext").addEventListener("click", () => {
    carousel.next()
})

// Touch Controls (Init)

let invalidTouch: boolean = false

let touchstartX: number = window.innerWidth / 2
let touchmoveX: number = window.innerWidth / 2

window.addEventListener("resize", () => {
    touchstartX = window.innerWidth / 2
    touchmoveX = window.innerWidth / 2
})

// Keyboard Controls

document.addEventListener("keydown", (e: KeyboardEvent) => {
    if (e.key == "ArrowLeft") {
        carousel.prev()
    } else if (e.key == "ArrowRight") {
        carousel.next()
    }
})

// Home Button

document.querySelector('#home-button').addEventListener("click", () => {
    galleryCategorySelectionDrawer._targetEl.remove()
})

// Category Selection

document.querySelector('#galleryCategorySelectionBar').addEventListener("click", () => {
    galleryCategorySelectionDrawer.toggle()
})

document.querySelector('div#galleryCategorySelectionDrawer').addEventListener("click", () => {
    galleryCategorySelectionDrawer.hide()
})

for (const i in gallery) {
    const clone = <HTMLButtonElement>(<HTMLTemplateElement>galleryCategoryTemplate.content.cloneNode(true)).firstElementChild!
    clone.textContent = gallery[i].name
    clone.onclick = function () {
        loadGallery(i, 0)
        document.querySelector("[drawer-backdrop]").dispatchEvent(new MouseEvent("click"))
    }

    galleryCategoryTemplate.parentElement!.appendChild(clone)
}

// Gallery Load

const defaultCategory = Object.keys(gallery)[0]
const defaultPosition = 0

const url = new URL(window.location.href)

if (!url.searchParams.get("c")) {
    url.searchParams.append("c", defaultCategory)
}
if (!url.searchParams.get("p")) {
    url.searchParams.append("p", defaultPosition.toString())
}

window.history.replaceState({}, document.title, url)

loadGallery(new URLSearchParams(window.location.search).get("c"), Number.parseInt(new URLSearchParams(window.location.search).get("p")))